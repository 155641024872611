<template>
    <div class=container>
        <table class=table width="73%" border="0" cellspacing="30" cellpadding="0">
          <tbody><tr>
            <td><div align="center"><font size="+2"></font></div></td>
          </tr>
<tr>
<td>
<p>The following tool/codes are provided to increase the applicability of the acquired data. Most of them are in Matlab. Some routines are written in C++ and compiled by mex for efficiency. The comments in the code contain detailed usage instructions. Please note this is "research-type" code and may have bugs; use it at your own risk.</p></td></tr>

          <tr>
            <td><div align="left">
                <h3 align="left">Synthesis Tool</h3>
[<a href="https://cave.cs.columbia.edu/old/databases/dirty_glass/synthesis.zip">Download (5.7MB, in Matlab)</a>]
              <p align="left">
			This is a simple interactive tool to synthesize large texture patterns from the acquired
contamination patterns. The synthesized
texture, in which each channel corresponds to one kind of
parameter for the contaminant, can be mapped on arbitrary
transparent objects for rendering.
The following image shows the interface.

We first build a database by selecting a collection of prototypes
for each of the three categories of contamination (dust, dirt, and
lipids) from the measured data. For synthesis, a user selects prototypes
from each category for the desired contamination pattern and
specifies the size of the output texture. For example, the desired
pattern might be a combination of fingerprints or smudges, some
stripes of water deposit, and a layer of dust. The tool then
synthesizes a contamination layer for each prototype individually.
For dust, we use <a href="http://graphics.cs.cmu.edu/people/efros/research/quilting.html">Image
Quilting</a> for synthesis. For
lipids (fingerprints), we randomly transform (scale and rotate)
the prototype and randomly tile several copies on the canvas. For
dirt, we use a similar random placement but we limit the range of
rotation and translation since the water stripes usually are on
the top of an image. The three synthesized layers are then
alpha-blended together to generate the final texture. A user can
control the amount and the range of transformation for each
prototype and also add/remove more prototypes interactively.</p>
              
                <div><center><img src="https://cave.cs.columbia.edu/old/databases/dirty_glass/images/setup.jpg"></center>
                      <br>
			</div>
<br>
                <h3 align="left">Estimation of g from the Falloff Image</h3>
[<a href="https://cave.cs.columbia.edu/old/databases/dirty_glass/estimateG.m">estimateG.m</a>][<a href="https://cave.cs.columbia.edu/old/databases/dirty_glass/fit_g_curve.m">fit_g_curve.m</a>]
<br><br>
<h3>EXR file I/O in Matlab</h3>[<a href="https://cave.cs.columbia.edu/old/databases/dirty_glass/exrio.zip">Download (454KB, in Matlab)</a>]
          </div></td></tr>
          <tr>
            <td><hr>
              <router-link to="/repository/Contaminants">Database Home</router-link><br>
Contact:&nbsp;<a href="mailto:jwgu@cs.columbia.edu">jwgu@cs.columbia.edu</a><br>
Last modified: 06/02/2007</td>
          </tr>
        </tbody></table>
    </div>
</template>

<script>
export default {

}
</script>

<style>
.container{
    width:70%;
    margin-left:auto;
    margin-right:auto;
    text-align: left;
	font-size: 18px;
}
.table{
        margin:auto;
}
</style>